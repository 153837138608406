import React from 'react';
import {Link} from "react-router-dom";

import BannerImage from "../../images/BGImage.jpg"
import Footer from "../FooterI"
import "./Services.css"
export default function Services() {
  const OpeninNewTab=url=>{
    window.open(url,'_blank','noopener,noreferrer');
  };
  return (
    <div className='container'>
     <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <h1> Diyakaran Enterprises </h1>
        <p> Proud distributors for Anthem Biosciences</p>
        
          <button> <a
            className="hyper__link"
            href="https://www.diyakaran.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Checkout Now
          </a> </button>
        
      </div>
    </div>
      <Footer/>
    </div>
    
        

  
  );
  
}

import  React from 'react';
import Gallery1 from "../Gallery1";
import Footer from '../FooterI';
import Slider from "../slider/Slider"
import "../Gallery1.css"
export default function Products(){
  return(
    <div>
            <h1 className='font-style'>Our Featured Products</h1>
      <Gallery1/>
      <Slider/>
      <Footer/>
      </div>
  )
}
import React from 'react'
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-share.css';




// If you want you can use SCSS instead of css


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgautoplay from 'lightgallery/plugins/autoplay';
import lgfullscreen from 'lightgallery/plugins/fullscreen';
import lgshare from 'lightgallery/plugins/share';
import lgrotate from 'lightgallery/plugins/rotate';
const images=[
    {src:"images1/img-1.jpeg",alt:"SonaGabaNT"},
    {src:"images1/img-2.jpeg",alt:"SonaPG-SR"},
    {src:"images1/img-3.jpeg",alt:"SonaBone-Plus"},
    {src:"images1/img-4.jpeg",alt:"Sona-B12"},
    {src:"images1/img-5.jpeg",alt:"SonaHealth"},
    {src:"images1/img-6.jpeg",alt:"SonaGut"},
    {src:"images1/img-7.jpeg",alt:"SonaPraz"},
    {src:"images1/img-8.jpeg",alt:"SonaPraz-D"},
    {src:"images1/img-9.jpeg",alt:"SonaPara"},
]

export default function Gallery1() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="App">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom,lgautoplay,lgfullscreen,lgshare,lgrotate]}
            >
                {images.map((image,index)=>{
                    return(
                        <a href={image.src} key={index}>
                        <img alt={image.alt} src={image.src} />
                    </a>
                    )
                })}
               
               
            </LightGallery>
        </div>
    );
}

import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Our Featured Products</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
           
              <CardItem
              src='images1/img-9.jpeg'
              text='Faster relief from Pain and Inflammation'
              label='Pain relief'
              path='/products'
            />
             <CardItem
              src='images1/img-3.jpeg'
              text='Feel the womanhood crisis'
              label='Promotes Calcium'
              path='/products'
            />
            
             
           
            <CardItem
              src='images1/img-2.jpeg'
              text='First line treatment for pinning pain'
              label='Pregablin'
              path='/products'
            />
              
          </ul>
          <ul className='cards__items'>
           
          
            <CardItem
              src='images1/img-1.jpeg'
              text='First line treatment for painful neuropathy'
              label='Neuropathy'
              path='/products'
            />
                        <CardItem
              src='images1/img-5.jpeg'
              text='Once a day for healthy neurons'
              label='Calcium & Multivitamins'
              path='/products'
            />
                        <CardItem
              src='images1/img-6.jpeg'
              text='Wipe off abdominal discomfort'
              label='Promtes gut health'
              path='/products'
            />
                      
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images1/img-7.jpeg'
              text='Taps uncontrolled hyper-acidity'
              label='Gastro-resistant'
              path='/products'
            />
            
            <CardItem
              src='images1/img-4.jpeg'
              text='Planned motherhood needs premium care'
              label='Maternity health'
              path='/products'
            />
            <CardItem
              src='images1/img-8.jpeg'
              text='Dual action for hyper-acidity and gut motility'
              label='Trusted Antacid'
              path='/products'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
